<template>
     <section class="page_title  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <div class="content_box">
                           <h6>Meet the Experts </h6>
                           <h1> The Project Team</h1>
                           <ul class="bread_crumb text-center">
                              <li class="bread_crumb-item"><a href="#">Home</a></li>
                              <li class="bread_crumb-item active"> Project Team </li>
                           </ul>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>


         <section class="our_experts_all pdt_100  pdb_100">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-4 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="@/assets/image/team/dr-rhoda-wanyenze-sph-01.jpg" class="img-fluid" alt="experts" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-8 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="content_box">
                           <div class="experts_details">
                              <h1><a href="#">PROF. RHODA WANYENZE </a></h1>
                              <small>Dean of MakSPH - Principal Investigator (Covid 19 Assessment Project )</small>
                           </div>
                           <p>Prof. Rhoda Wanyenze, MBChB, MPH, PhD, is a Professor and Dean of Makerere University School of Public Health (MakSPH). She has vast experience in infectious diseases research, capacity building and program management, especially in HIV and TB and has also conducted several studies in maternal and child health. Prior to joining MakSPH, Dr. Wanyenze was the Program Manager for the Makerere University Joint AIDS Program.
                           </p>
                           <p> Her work has been funded by the NIH, CDC, Global Fund, among others. She has developed and sustained several national and international research and service collaborations.</p>
                           <!-- <div class="expert_contact">
                              <h2>Contact details:</h2>
                              <h6><span> Email:</span> <a href="#">rwanyenze@musph.ac.ug</a></h6>
                           
                           </div> -->
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
                  <!----empty-space-->
                  <div class="empty_space pdb_40 pdt_40"></div>
                  <!----empty-space-->
                  <div class="row" >
                     <!---------row----------->
                     <div class="col-lg-8 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="content_box">
                           <div class="experts_details">
                              <h1><a href="#">PROF. JULIUS FOBIL</a></h1>
                              <small>Country Principal Investigator for Ghana (Covid 19 Assessment Project ) University of Ghana</small>
                           </div>
                           <p>Julius Fobil is a Professor of Environmental Health with an outstanding academic career; having contributed significantly to the field of Environmental Epidemiology in Ghana and beyond.
 He has over hundred peer review publications in the field and has trained more than 80 students at undergraduate and doctoral levels, including postdoctoral fellows. He is a fellow of the Collegium Ramazzini, an academy of internationally renowned fellows in Environmental and Occupational Health and a Fellow of Ghana Academy of Arts and Sciences. He is the Provost of the University of Ghana’s College of Health Sciences; having previously served as Dean of its School of Public Health. </p>
 
 <p>Also currently serving as the Interim Director of the West Africa Centre for Global Environmental & Occupational Health, he is also an Associate Scientist at the Bernhard Nocht Institute for Tropical Medicine in Hamburg. His research focuses on environmental exposure assessment; including such assessments in the informal sector economy, urban environmental health in low-income economies and more recently, he has been involved in research capacity building in environmental and occupational health in West and Central Africa regions. 
                           </p>
                       
                           <!-- <div class="expert_contact">
                              <h2>Contact details:</h2>
                              <h6><span> Email:</span> <a href="#">fwabwire@musph.ac.ug</a></h6>
                           
                           </div> -->
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-4 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="@/assets/image/team/fobil.jpg" class="img-fluid" alt="experts" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
                  <!----empty-space-->
                  <div class="empty_space pdb_40 pdt_40"></div>
                  <!----empty-space-->
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-4 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="@/assets/image/team/dr-susan-kiwanuka.jpg" class="img-fluid" alt="experts" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-8 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="content_box">
                           <div class="experts_details">
                              <h1><a href="#">DR. SUZANNE KIWANUKA</a></h1>
                              <small>Country Principal Investgator -Makerere University - UG</small>
                           </div>
                           <p> Dr. Suzanne Kiwanuka is a Senior Lecturer in Health Policy Planning and Management at Makerere University School of Public Health (Kampala, Uganda) with a passion and vast experience in health systems strengthening and evidence based policy making through strengthening of implementation research.
                           </p>
                           <p>Dr. Suzanne holds a doctorate in Oral epidemiology and international health from the University of Bergen, Norway, a masters degree in public health and is a doctor of dental surgery. Dr. Suzanne has led several projects as a PI and Co-PI. She is a co-team leader on a project aimed at saving lives of mothers and newborns through community empowerment and health systems strengthening in three districts in Uganda.</p>
                 
                           <!-- <div class="expert_contact">
                              <h2>Contact details:</h2>
                              <h6><span> Email:</span> <a href="#">skiwanuka@musph.ac.ug</a></h6>
                           </div> -->
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
                  <!----empty-space-->
                  <div class="empty_space pdb_40 pdt_40"></div>
                  <!----empty-space-->
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-8 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="content_box">
                           <div class="experts_details">
                              <h1><a href="#">PROF. MALA ALI MAPATANO</a></h1>
                              <small>Country Principal Investgator - University of Kishasha -DRC</small>
                           </div>
                           <p> Professor Mapatano Mala received his medical degree from the Kinshasa University in the Democratic Republic of Congo (DRC) in 1977. From 1986 to 1993, Prof Mapatano received a Master’s degree and a PhD in Public Health from Tulane University, United States of America. Since April 2004, he is Professor of Public Health, Epidemiology and Nutrition at the Public Health School of Kinshasa and Director of the National Centre for the Epidemiology of Diabetes, DRC.
                           </p>
                           <p>  From 1995 to 1997, he was the Director of the “Mont Amba” Hospital Center of the University of Kinshasa, DRC. Prof Mapatano is a former Deputy Director of the Public Health School in Kinshasa and former Cabinet Director of Ministry of Health, DRC.</p>
                           <!-- <div class="expert_contact">
                              <h2>Contact details:</h2>
                              <h6><span> Email:</span> <a href="#"></a></h6>
                             
                           </div> -->
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-4 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="@/assets/image/team/Mapatano-1024x768.jpg" class="img-fluid" alt="experts" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>

                   <!----empty-space-->
                  <div class="empty_space pdb_40 pdt_40"></div>
                  <!----empty-space-->
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-4 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="@/assets/image/team/proffawole.jpg" class="img-fluid" alt="experts" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-8 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="content_box">
                           <div class="experts_details">
                              <h1><a href="#">PROF. OLUFUNMILAYO I. FAWOLE</a></h1>
                              <small>Country Principal Investgator - University of Ibadan- NG</small>
                           </div>
                           <p>Olufunmilayo I. Fawole is a Professor of Epidemiology and the current Dean of the Faculty of Public Health in the University of Ibadan, Nigeria. She is a Public Health Physician and also an Honorary Consultant with the University College Hospital, Ibadan. She has over 15 years of work experience on Gender based Violence research/ Women Empowerment and is one of the leading researchers in the field in Nigeria. She has over 70 peer reveiwed publications and 15 research grants in this discipline.
                           </p>
                           <p> She just completed a three phased mixed method study that determined the content and strategies to be used in the design of a GBV curriculum for medical students. Presently, she is working on a project that aims to empower young girls and women by providing financial literacy using a mobile technology.</p>
                 
                           <!-- <div class="expert_contact">
                              <h2>Contact details:</h2>
                              <h6><span> Email:</span> <a href="#">fawoleo@ymail.com</a></h6>
                           </div> -->
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
                  <!----empty-space-->
                  <div class="empty_space pdb_40 pdt_40"></div>
                  <!----empty-space-->
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-8 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="content_box">
                           <div class="experts_details">
                              <h1><a href="#">PROF. IBRAHIMA SECK</a></h1>
                              <small>Country Principal Investgator - University of Dakar -Senegal</small>
                           </div>
                           <p>Prof. Seck holds a Diploma in Medicine, (FMPO / UCAD Senegal), a Certificate of Specialization in Public health (ISED / UCAD, Senegal), a Master’s degree in International Development and a PhD (Tulane University, New Orleans, USA). He is also a full Professor of universities (CAMES, 2012) and has nearly thirty years of experience in public health.
                           </p>
                           <p> Before joining the Faculty of Medicine, Pharmacy and Odontology at UCAD, Professor Seck was a health district Chief Medical Officer, Coordinator of the National Tuberculosis Control Program in Senegal, Technical Advisor No. 1, Chief of staff of the Minister of Health and Social Action of Senegal, and Technical Advisor to the Presidency of the Republic of Senegal.</p>
                           <!-- <div class="expert_contact">
                              <h2>Contact details:</h2>
                              <h6><span> Email:</span> <a href="#">birimaseck@hotmail.com</a></h6>
                             
                           </div> -->
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-4 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="@/assets/image/team/1640945890901.jpg" class="img-fluid" alt="experts" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
         </section>


          <section class="experts type_one pdt_100  pdb_70" style="background-color:rgba(244, 233, 203, 0.3)">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12">
                        <!---------col----------->
                        <div class="heading text-center tp_one">
                           <h6> Covid 19 Assessment Project  </h6>
                           <h1>Project Secretariat</h1>
                          
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-sm-12 padding_zero" id="projectsecret">
                        <!---------col----------->
                        <div class="owl-carousel three_items">
                           <!-------expert_box---------->
                           <div class="expert_box type_one " >
                              <div class="image_box">
                                 <img src="@/assets/image/team/susankizito.jpg" class="img-fluid" alt="experts" />
                              </div>
                              <div class="content_box">
                                 <div class="expert_details">
                                    <h2><a href="#">Dr. Susan Kizito</a></h2>
                                    <p>Deputy Coordinator </p>
                                 </div>
                                <p>She leads the work on intended consequences of non-
pharmaceutical interventions during the COVID-19 response, capacity building and the
community of practice work.</p>
                                <a  href="#" @click.prevent="opensusankizito"> Read More about Me</a>
                              </div>
                           </div>
                           <!--------expert_box-wnd-------->
                           <!-------expert_box---------->
                           <div class="expert_box type_one ">
                              <div class="image_box">
                                 <img src="@/assets/image/team/stevenndugwakabwama.jpg" class="img-fluid" alt="experts" />
                              </div>
                              <div class="content_box">
                                 <div class="expert_details">
                                    <h2><a href="#">Steven Ndugwa Kabwama</a></h2>
                                    <p> Epidemiological expert</p>
                                 </div>

                                 <p>He leads the work-stream that reviews the preparedness of health systems to
respond to COVID-19 and documents country efforts to maintain essential health services
during COVID-19 response.</p>

                               <a  href="#" @click.prevent="openkabwama">Read More about Me</a>
                                
                              </div>
                           </div>
                           <!--------expert_box-wnd-------->
                           <!-------expert_box---------->
                           <div class="expert_box type_one " >
                              <div class="image_box">
                                 <img src="@/assets/image/team/rawlancendejjo.jpg" class="img-fluid" alt="experts" />
                              </div>
                              <div class="content_box">
                                 <div class="expert_details">
                                    <h2><a href="#">Dr. Rawlance Ndejjo</a></h2>
                                    <p>Coordinator Covid19 Assessment Project</p>
                                 </div>
                                 <p>He
leads  work on unintended consequences of COVID-19 and uptake of COVID-19
vaccinations and his other related work has been in community engagement for
COVID-19 control</p>
                                 <a  href="#" @click.prevent="openrwalance">Read More about Me</a>
                                 
                              </div>
                           </div>
                           <!--------expert_box-wnd-------->

                           <!-------expert_box---------->
                           <div class="expert_box type_one " >
                              <div class="image_box">
                                 <img src="@/assets/image/team/J.N.Nanteza.jpg" class="img-fluid" alt="experts" />
                              </div>
                              <div class="content_box">
                                 <div class="expert_details">
                                    <h2><a href="#">Jackie Norah Nanteza</a></h2>
                                    <p>Coordinator Covid19 Assessment Project</p>
                                 </div>
                                 <p>Ms Nanteza has
managed grants funded by the Bill and Melinda Gates Foundation, the Global Fund and
USAID among other donors. Presently, she manages grants and sub-contracts </p>
                                 <a  href="#" @click.prevent="opennorah">Read More about Me</a>
                                 
                              </div>
                           </div>
                           <!--------expert_box-wnd-------->
                        </div>
                        <!---------col-end---------->
                     </div>



                  <div id="openrwalance" class=" row hideall">
                    <div class="col-lg-4 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="@/assets/image/team/rawlancendejjo.jpg" class="img-fluid" alt="experts" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!---------row----------->
                     <div class="col-lg-8 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="content_box">
                           <div class="experts_details">
                              <h1><a href="#">DR. RAWLANCE NDEJJO</a></h1>
                           </div>
                           <p>Dr. Rawlance Ndejjo is an academic and researcher in the Department of Disease Control and
Environmental Health at Makerere University School of Public Health and the Secretariat
Coordinator of the COVID-19 Assessment project. He holds a PhD in Medical Sciences from the
University of Antwerp, Belgium; an MSc in Environmental Health degree from the University of
the West of England, United Kingdom; and a Bachelor’s degree in Environmental Health
Science from Makerere University, Uganda. On the COVID-19 assessment project, Rawlance
leads the work on unintended consequences of COVID-19 and uptake of COVID-19
vaccinations. 
                           </p>
                           <p>His other COVID-19 related work has been in community engagement for
COVID-19 control including for increasing vaccination uptake and evaluating experiences of
quarantined individuals. He has published over 70 peer-reviewed articles in various fields
including cardiovascular disease, cervical cancer, and health systems.</p>
                           <div class="expert_contact float-right">
                            <h6><a class="btn btn-primary " @click.prevent="openrwalance" href="#">Close Profile</a></h6>
                           </div>
                        </div>
                        <!---------col-end---------->
                     </div>
                     
                     <!----------row-end---------->
                  </div>


                  <div id="openkabwama" class=" row hideall">
                    <div class="col-lg-4 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="@/assets/image/team/stevenndugwakabwama.jpg" class="img-fluid" alt="experts" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!---------row----------->
                     <div class="col-lg-8 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="content_box">
                           <div class="experts_details">
                              <h1><a href="#">STEVEN NDUGWA KABWAMA</a></h1>
                           </div>
                           <p>Mr. Kabwama is an epidemiologist with a Bachelors Degree in Food Science and Technology
from Makerere University, Uganda and a master’s degree in Public Health from the
University of Southern Denmark. He previously worked as a Field Epidemiology Fellow and
focused on non-communicable disease epidemiology, disease outbreak investigation and
response. He currently works at Makerere University School of Public Health as a
Monitoring &amp; Evaluation Specialist on a post graduate Field Epidemiology Fellowship. 
                           </p>
                           <p>He
has several publications about the epidemiology of risk factors for noncommunicable
diseases as well as disease surveillance, response and control. On the COVID-19 assessment
project he has led the workstream that reviewed the preparedness of health systems to
respond to COVID-19 and documented country efforts to maintain essential health services
during the COVID-19 response.</p>
                           <div class="expert_contact float-right">
                            <h6><a class="btn btn-primary " @click.prevent="openkabwama" href="#">Close Profile</a></h6>
                           </div>
                        </div>
                        <!---------col-end---------->
                     </div>
                     
                     <!----------row-end---------->
                  </div>


                  <div id="opensusankizito" class=" row hideall">
                    <div class="col-lg-4 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="@/assets/image/team/susankizito.jpg" class="img-fluid" alt="experts" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!---------row----------->
                     <div class="col-lg-8 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="content_box">
                           <div class="experts_details">
                              <h1><a href="#">DR. SUSAN KIZITO</a></h1>
                           </div>
                           <p>Dr. Kizito Susan is a medical doctor with a Bachelor’s degree from Mbarara University of
Science and Technology, and MSc in International Public Health from The Institute of Tropical
Medicine and International Health (ITMIH), Charité – Universitätsmedizin Berlin, Germany.
She is also an epidemiologist trained through the didactic Advanced Field Epidemiology
Training Program of Uganda and is working with the Makerere University School of Public
Health and the Neglected Tropical Diseases Control Programme, Vector Control Division at the
Ministry of Health. 
                           </p>
                           <p> At the Secretariat, Susan is working as a deputy coordinator of the COVID-
19 Assessment project, where she leads the work on intended consequences of non-
pharmaceutical interventions during the COVID-19 response, capacity building and the
community of practice work.</p>
                           <div class="expert_contact float-right">
                            <h6><a class="btn btn-primary " @click.prevent="opensusankizito" href="#">Close Profile</a></h6>
                           </div>
                        </div>
                        <!---------col-end---------->
                     </div>
                     
                     <!----------row-end---------->
                  </div>

                  <div id="opennorah" class=" row hideall">
                    <div class="col-lg-4 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="@/assets/image/team/J.N.Nanteza.jpg" class="img-fluid" alt="experts" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!---------row----------->
                     <div class="col-lg-8 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="content_box">
                           <div class="experts_details">
                              <h1><a href="#">JACKIE NORAH NANTENZA</a></h1>
                           </div>
                           <p>Jackie is the Grant and Sub-contracting management specialist at Makerere University
School of Public Health. On the COVID-19 Assessment project, she manages the grant and
its sub-grantees from Nigeria, Ghana, Senegal, DRC and Uganda and serves a liaison
between the donor and project team on grants management matters. She has a wealth of
experience managing sub-grantees and partners within the country and internationally. She
ensures compliance to donor regulation and manages the partnership. 
                           </p>
                           <p>Ms Nanteza has
managed grants funded by the Bill and Melinda Gates Foundation, the Global Fund and
USAID among other donors. Presently, she manages grants and sub-contracts with partners in
east, central, southern and west Africa.</p>
                           <div class="expert_contact float-right">
                            <h6><a class="btn btn-primary " @click.prevent="opennorah" href="#">Close Profile</a></h6>
                           </div>
                        </div>
                        <!---------col-end---------->
                     </div>
                     
                     <!----------row-end---------->
                  </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
</template>

<script>
export default {
   

   methods:{
      opennorah(){
         if($('#projectsecret').hasClass('hideall')){
         $('#projectsecret').removeClass('hideall')
         $('#opennorah').addClass('hideall')
         }else{
         $('#projectsecret').addClass('hideall')
         $('#opennorah').removeClass('hideall')
         }
         
      },

      openrwalance(){
         if($('#projectsecret').hasClass('hideall')){
         $('#projectsecret').removeClass('hideall')
         $('#openrwalance').addClass('hideall')
         }else{
         $('#projectsecret').addClass('hideall')
         $('#openrwalance').removeClass('hideall')
         }
         
      },

      openkabwama(){
         if($('#projectsecret').hasClass('hideall')){
         $('#projectsecret').removeClass('hideall')
         $('#openkabwama').addClass('hideall')
         }else{
         $('#projectsecret').addClass('hideall')
         $('#openkabwama').removeClass('hideall')
         }
         
      },

      opensusankizito(){
         if($('#projectsecret').hasClass('hideall')){
         $('#projectsecret').removeClass('hideall')
         $('#opensusankizito').addClass('hideall')
         }else{
         $('#projectsecret').addClass('hideall')
         $('#opensusankizito').removeClass('hideall')
         }
         
      },
   }
}
</script>
<style>
.hideall{
  display:none !important
}
</style>